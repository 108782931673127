
export default class ScriptComponentLoader {
    constructor() {
    }

    getAndLoad(array, componentMap) {
        const componentElements = document.querySelectorAll('[data-component-type]')
        // Convert NodeList to an array
        const elementsArray = Array.from(componentElements)
        // Sort the elements based on their position in the DOM tree
        elementsArray.sort((a, b) => {
            const aPosition = Array.from(a.parentElement.children).indexOf(a)
            const bPosition = Array.from(b.parentElement.children).indexOf(b)
            return aPosition - bPosition
        })
        elementsArray.forEach((el) => {
            const componentName = el.getAttribute('data-component-type')
            if (componentMap[componentName]) {
                array.push(new componentMap[componentName](el))
                array[array.length - 1].init()
                //console.log(`Initialized component ${componentName}`)
            } else {
                console.warn(`Component ${componentName} not found in componentMap.`)
            }
        })
    }

    filterAndLoad(array, componentMap, data) {
        // Get the components present in the new page
        const newComponentElements = Array.from(data.next.container.querySelectorAll('[data-component-type]'))
        // Sort the newComponentElements based on their position in the DOM tree
        newComponentElements.sort((a, b) => {
            const aPosition = Array.from(a.parentElement.children).indexOf(a)
            const bPosition = Array.from(b.parentElement.children).indexOf(b)
            return aPosition - bPosition
        })
        // Initialize components in the sorted order
        newComponentElements.forEach((el) => {
            const componentName = el.getAttribute('data-component-type')
            if (componentMap[componentName]) {
                array.push(new componentMap[componentName](el))
                array[array.length - 1].init()
               // console.log(`Initialized component ${componentName}`)
            } else {
                console.warn(`Component ${componentName} not found in componentMap.`)
            }
        })
    }

    unmountComponents(array, data) {
        array.forEach(component => {
            if (data.current.container.contains(component.element)) {
                component.unmount()
                // console.log(`unmounting`)
            }
        })
    }
}