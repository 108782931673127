// import 'lazysizes'
import mitt from 'mitt'

window.emitter = mitt()

/**
 * GLOBALS
 */
import SmoothScroll from './globals/SmoothScroll'
import CreateBarba from './globals/CreateBarba'
import PageTransition from './globals/PageTransitions'
import ScriptComponentLoader from './globals/ScriptComponentLoader'
import BarbaBodyClass from './globals/BarbaBodyClass'

import Preloader from './components/c-Preloader'

/**
 * HELPERS
 */
import { Utils } from './helpers/Utils'


/**
 *  GLOBAL COMPONENTS
 */
import HeroMedia from './components/c-HeroMedia'
import ServiceItem from './components/c-ServiceItem'
import NavigationBar from './components/c-NavigationBar'
import MenuModal from './components/c-MenuModal'
import Footer from './components/c-Footer'
import OnTheMenuModal from './components/c-OnTheMenuModal'
import AccordionItem from './components/c-AccordionItem'
import StickyScrollerItem from './components/c-StickyScrollerItem'
import Blurb from './components/c-Blurb'


// string must appear as it does in the PHP class file
const componentMap = {
	'heroMedia': HeroMedia,
	'serviceItem': ServiceItem,
	'navigationBar': NavigationBar,
	'menuModal': MenuModal,
	'footer': Footer,
	'onTheMenuModal': OnTheMenuModal,
	'accordionItem': AccordionItem,
	'stickyScrollerItem': StickyScrollerItem,
	'blurb': Blurb
}

let instance = null

class BaseTheme {
	constructor() {

		if(instance) {
			return instance
		}

		window.isTouchscreen 		= Utils.isTouchDevice()

		this.scriptComponentLoader 	= new ScriptComponentLoader()
		this.bobyClassHelper		= new BarbaBodyClass()
		this.barbaRef 				= new CreateBarba().barba
		this.pageTransition 		= new PageTransition({ element: document.querySelector('.c-PageTransition') })
		this.preloader 				= new Preloader({ element: document.querySelector('.c-Preloader') })

		if(!window.isTouchscreen) {
			window.smoothScroll 	= new SmoothScroll()
		}

		this.init()
		this.createBarba()
	}

	init() {

		this.scriptRefs = [] // Clear existing scriptRefs, if any

		this.scriptComponentLoader.getAndLoad(this.scriptRefs, componentMap)
	}

	initOnNewPage(data) {

		return new Promise((resolve) => {

			this.scriptRefs = [] // Clear existing scriptRefs

			this.scriptComponentLoader.filterAndLoad(this.scriptRefs, componentMap, data)

			resolve()
		})
	}

	createBarba() {

		this.barbaRef.init({
			prevent: ({ el }) => {
				return el && el.href.includes('rumours')
			},
			schema: {
				prefix: 'data-transition',
			},
			// debug: true,
			transitions: [
				{
					name: 'default-transition',
					once: async (data) => {

						document.body.style.cursor = 'wait'

						setTimeout(() => {
							window.scrollTo(0, 0)
						}, 100)

						await new Promise(resolve => {
							this.preloader.animate(resolve)
						})

					},
					leave: async (data) => {

						await new Promise(resolve => {
							this.pageTransition.leave(resolve)
						})

						await new Promise(resolve => {
	
							this.scriptComponentLoader.unmountComponents(this.scriptRefs, data)
							resolve()
						})
						
					},
					beforeEnter: (data) => {

						this.bobyClassHelper.getClasses(data)
					},
					after: async (data) => {

						await this.initOnNewPage(data)

						this.pageTransition.after()
					}
				}
			]
		})
	}

	resize() {}

	update() {}
}


document.addEventListener('DOMContentLoaded', () => {
	new BaseTheme()
})
