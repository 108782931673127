import GSAP from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

GSAP.registerPlugin(ScrollTrigger)

export default class NavigationBar {
	constructor(el, index) {
		this.element 			= el
		this.index 				= index

		this.menuToggle 		= this.element.querySelector('button')
		this.handleClickEvent 	= this.handleClickEvent.bind(this)

		this.themeType 			= this.element.getAttribute('data-theme')

		this.STInstance 		= null
		this.isExpanded 		= false

		this.toggleStateChange 	= this.toggleStateChange.bind(this)
		this.setStateFalse 		= this.setStateFalse.bind(this)
	}
  
	init() {

		this.menuToggle.addEventListener('click', this.handleClickEvent)

		window.emitter.on('menuToggled', this.toggleStateChange)
		window.emitter.on('menuClose', this.setStateFalse)

		if(this.themeType == 'light') {
			this.setupScrollTrigger()
		}
	}

	toggleStateChange() {

		if(!this.isExpanded) {
			this.element.classList.add('menu-active')
		} else {
			this.element.classList.remove('menu-active')
		}

		this.isExpanded = !this.isExpanded
	}

	setStateFalse() {

		if(!this.isExpanded) return
	
		this.isExpanded = false

		this.element.classList.remove('menu-active')
	}

	handleClickEvent(e) {

		window.emitter.emit('menuToggled')
	}

	setupScrollTrigger() {

		this.STInstance = ScrollTrigger.create({
			trigger: this.element,
			start: 'top top',
			end: () => `${window.innerHeight}`,
			onLeave: () => {
				this.onActive()
			},
			onEnterBack: () => {
				this.onInActive()
			}
		})
	}

	onActive() {
		this.element.classList.add('in-active')
	}

	onInActive() {
		this.element.classList.remove('in-active')
	}
  
	unmount() {
		if(this.STInstance) this.STInstance.kill()
		this.menuToggle.removeEventListener('click', this.handleClickEvent)

		window.emitter.off('menuToggled', this.toggleStateChange)
		window.emitter.off('menuClose', this.setStateFalse)
	}
}
