import GSAP from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

GSAP.registerPlugin(ScrollTrigger)

export default class StickyScrollerItem {
	constructor(el, index) {
		this.element 			= el
		this.index 				= index

		this.STDefault			= null
		this.STSingleColumn 	= null
		this.STFiveCol 			= null
		this.STPinned 			= null
		this.STFullBleed 		= null
		this.STPinOver 			= null
		this.STPinHorizontal 	= null

		this.tlHorizonal		= null

		this.mmFullBleed 		= GSAP.matchMedia()
		this.mmHorizontal 		= GSAP.matchMedia()

		this.blurb 				= this.element.getAttribute('data-blurb')

		this.dataOptions		= this.element.getAttribute('data-options')
		this.parsedData 		= JSON.parse(this.dataOptions)

		this.columns 			= [...this.element.querySelectorAll('.c-StickyScrollerItem__column')]
	}

	init() {

		// if(this.parsedData.type !== 'horizontal') {
			this.setDefaultScrollTriggers()
		// }

		if(this.parsedData.type === 'single-column-full-bleed') {
			this.scaleMediaOnScroll()
		}

		if(this.parsedData.type === 'five-column') {
			this.setupFiveColumn()
		}

		if(this.parsedData.type === 'panel-pinner') {
			this.setupPanelPinner()
		}

		if(this.parsedData.type === 'full-bleed') {
			this.pinFullBleed()
		}

		if(this.parsedData.type === 'scroll-over') {
			this.pinScrollOver()
		}

		if(this.parsedData.type === 'horizontal') {
			this.pinHorizontal()
		}

		if(this.parsedData.type === 'scatter') {
			this.setupScatter()
		}
	}

	setDefaultScrollTriggers() {

		const columns = [...this.element.querySelectorAll('.c-StickyScrollerItem__column')]

		columns.forEach(column => {

			const blurb = column.getAttribute('data-blurb')

			this.STDefault = ScrollTrigger.create({
				trigger: column,
				start: 'top bottom',
				end: 'bottom bottom',
				invalidateOnRefresh: true,
				onEnter: () => { 
					// console.log('enter')
					window.emitter.emit('updateBlurb', {
						blurb: blurb
					})
				},
				onEnterBack: () => {
					// console.log('enter back')
					window.emitter.emit('updateBlurb', {
						blurb: blurb
					})
				}
			})
		})
	}

	scaleMediaOnScroll() {

		const column 		= this.element.querySelector('.c-StickyScrollerItem__column')
		const columnImg 	= this.element.querySelector('.c-StickyScrollerItem__column img')

		this.STSingleColumn = GSAP.timeline()
	
			this.STSingleColumn.to(columnImg, {
				ease: 'none',
				scale: () => window.innerHeight > column.offsetHeight || window.innerWidth < 700 ? 0.5 : window.innerHeight / column.offsetHeight,
				/**
				 * create a scale to match screenheight on finish if image is greater than the viewport,
				 * else, scale down to a temp value.
				 * */
				scrollTrigger: {
					trigger: column,
					pin: true,
					scrub: columnImg,
					start: 'center center',
					invalidateOnRefresh: true,
					end: () => `+=${column.offsetHeight}`
				}
			})
	}

	pinFullBleed() {

		this.mmFullBleed.add("(max-width: 1024px)", () => {

			const columns = this.element.querySelectorAll('.c-StickyScrollerItem__column')

			columns.forEach((c, idx) => {
	
				this.STFullBleed = GSAP.timeline({
					scrollTrigger: {
						trigger: c,
						start: () => 'top top',
						end: () => `+=${window.innerHeight}`,
						pin: idx == 0 ? true : false,
						scrub: true,
						pinSpacing: false,
						invalidateOnRefresh: true,
					}
				})

			})

			return () => {
				this.STFullBleed.kill() 
				this.STFullBleed = null
			}
		})
	}

	setupFiveColumn() {

		const column 			= this.element.querySelector('.c-StickyScrollerItem__inner')
		const columnImagery		= column.querySelectorAll('.c-StickyScrollerItem__column img')

		columnImagery.forEach((img, idx) => {

			GSAP.set(img, {
				y:`${100 + Math.cos(idx + 1) * (200)}%`,
			})
		})

		columnImagery.forEach((img, idx) => {

			this.STFiveCol = GSAP.timeline()

			this.STFiveCol.to(img, {
				ease: 'none',
				y:`-${Math.cos(idx + 1) * (100)}%`,
				scrollTrigger: {
					trigger: this.element,
					start: 'top bottom',
					end: 'bottom top',
					scrub: true,
					invalidateOnRefresh: true,
				}
			})
		})
	}

	setupPanelPinner() {
		const columns = this.element.querySelectorAll('.c-StickyScrollerItem__column')

		columns.forEach((c, idx) => {

			const image = c.querySelector('img')

			this.STPinned = GSAP.timeline({
				scrollTrigger: {
					trigger: c,
					start: () => 'top top',
					end: () => `+=${idx == 0 ? window.innerHeight * 2 : window.innerHeight}`,
					pin: true,
					scrub: true,
					pinSpacing: false,
					invalidateOnRefresh: true,
				}
			})

			if(idx == 2) {

				this.STPinned.to(image, {
					ease: 'none',
					y: '-20%'
				})
			}

		})
	}

	pinScrollOver() {
		const columns = this.element.querySelectorAll('.c-StickyScrollerItem__column')

		columns.forEach((c, idx) => {

			this.STPinOver = GSAP.timeline({
				scrollTrigger: {
					trigger: c,
					start: () => 'top top',
					end: () => `+=${idx == 0 ? window.innerHeight * 2 : window.innerHeight}`,
					pin: idx == 0 ? true : false,
					pinSpacing: false,
					invalidateOnRefresh: true,
					// onEnter: () => {
					// 	console.log('enter')
					// },
					// onEnterBack: () => {
					// 	console.log('enter back')
					// }
				}
			})
		})
	}

	pinHorizontal() {

		let showBlurb = null
		let blurbToggled

		let showBlurbAdd = null
		let blurbAddToggled

		const column 			= this.element.querySelector('.c-StickyScrollerItem__column')
		const columnItems 		= [...this.element.querySelectorAll('img')]
		const blurb 			= column.getAttribute('data-blurb')
		const blurbAdditional 	= column.getAttribute('data-blurb-additional')

		this.tlHorizonal = GSAP.timeline({
			paused: true,
			scrollTrigger: {
				trigger: this.element,
				start: 'top top',
				end: () => `+=${column.offsetWidth -(window.innerWidth)}px`,
				scrub: true,
				pin: true,
				invalidateOnRefresh: true,
				onEnter: () => {
					window.emitter.emit('updateBlurb', {
						blurb: blurb
					})
				},
				onEnterBack: () => {
					window.emitter.emit('updateBlurb', {
						blurb: blurbAdditional
					})
				},
				onUpdate: (self) => {
					if(self.progress.toFixed(1) >= 0.5) {
						showBlurbAdd = true
	
						/**
						 * WE DO THIS SO IT ONLY FIRES ONCE IN AN UPDATE FUNC
						 * */
						if(showBlurbAdd !== blurbAddToggled) {
							blurbAddToggled = showBlurbAdd
							//console.log('second half')
							window.emitter.emit('updateBlurb', {
								blurb: blurbAdditional
							})
						}
					} else {
						showBlurbAdd = false
						blurbAddToggled = null
					}
	
					if(self.progress.toFixed(1) < 0.5) {
						showBlurb = true
	
						/**
						 * WE DO THIS SO IT ONLY FIRES ONCE IN AN UPDATE FUNC
						 * */
						if(showBlurb !== blurbToggled) {
							blurbToggled = showBlurb
							//console.log('first half')
							window.emitter.emit('updateBlurb', {
								blurb: blurb
							})
						}
					} else {
						showBlurb = false
						blurbToggled = null
					}
				}
			}
		})


		this.mmHorizontal.add({
			  isDesktop: `(min-width: ${768}px)`,// temp values
			  isMobile: `(max-width: ${768 - 1}px)`,// temp values
			},
			(context) => {
				let { isDesktop, isMobile } = context.conditions

				if(isDesktop) {
					
					this.tlHorizonal.fromTo(columnItems[1],
						{ 
							yPercent: `${100}`,
						},
						{ 
							yPercent: 0,
							ease: 'none',
						})

					this.tlHorizonal.fromTo(columnItems[2],
						{ 
							yPercent: `${150}`,
						},
						{ 
							yPercent: 0,
							ease: 'none',
						}, '<')
		
					this.tlHorizonal.fromTo(columnItems[3],
						{ 
							yPercent: `${200}`,
						},
						{ 
							yPercent: 0,
							ease: 'none',
						}, '-=0.5')

					this.tlHorizonal.to(column, {
						ease: 'none',
						x: () => `-=${column.offsetWidth -(window.innerWidth)}px`,
					})
				}

				if(isMobile) {

					this.tlHorizonal.to(column, {
						ease: 'none',
						x: () => `-=${column.offsetWidth -(window.innerWidth)}px`,
					})
			
				}

				return () => {
					
				}
			})
	}

	setupScatter() {
		const columns		= [...this.element.querySelectorAll('.c-StickyScrollerItem__column__item')]
		const columnImagery	= [...this.element.querySelectorAll('.c-StickyScrollerItem__column img')]

		columnImagery.forEach((img, idx) => {

			this.STScatter = GSAP.timeline()

			this.STScatter.to(img, {
				ease: 'none',
				y:`${100 * parseFloat(columns[idx].getAttribute('data-speed'))}%`,
				scale: 1.2,
				scrollTrigger: {
					trigger: this.element,
					start: 'top bottom',
					end: 'bottom top',
					scrub: true,
					invalidateOnRefresh: true,
				}
			})

		})
	}

	unmount() {

		if(this.STDefault) {
			this.STDefault.kill()
			this.STDefault = null
		}

		if(this.STPinned) {
			this.STPinned.kill()
			this.STPinned = null
		}

		if(this.STFiveCol) {
			this.STFiveCol.kill()
			this.STFiveCol = null
		}

		if(this.STSingleColumn) { 
			this.STSingleColumn.kill()
			this.STSingleColumn = null
		}

		if(this.STFullBleed) {
			this.STFullBleed.kill() 
			this.STFullBleed = null
		}

		if(this.STPinOver) {
			this.STPinOver.kill()
			this.STPinOver = null
		}

		if(this.tlHorizonal) {
			this.tlHorizonal.kill()
			this.tlHorizonal = null
		}
	}
}
