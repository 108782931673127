import GSAP from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

GSAP.registerPlugin(ScrollTrigger)

export default class HeroMedia {
	constructor(el, index) {
		this.element 	= el
		this.index 		= index

		this.background = this.element.querySelector('.c-HeroMedia__background')

		this.STInstance = null
		this.tl 		= null

	}
  
	init() {

		this.setScrollTrigger()
	}

	setScrollTrigger() {
		this.tl = GSAP.timeline()

		this.tl.to(this.background, {
			ease: 'linear',
			opacity: 0.7
		})

		this.STInstance = ScrollTrigger.create({
			trigger: this.element,
			start: 'top top',
			pin: true,
			animation: this.tl,
			scrub: true,
			pinSpacing: false,
			end: () => `+=${window.innerHeight}px`
		})
	}
  
	unmount() {
		if(this.STInstance) this.STInstance.kill()
		if(this.tl) this.tl.kill()
	}
}
