import GSAP from 'gsap'
import { Animations } from '../helpers/Animations'

export default class PageTransition {
	constructor(config) {
		this.element = config.element
	}

	leave(resolve) {

		const TL = GSAP.timeline()

		TL.set(this.element, {
			x: 0,
			autoAlpha: 0
		})

		TL.to(this.element, {
			autoAlpha: 1,
			duration: Animations.timing.crossFade,
			ease: Animations.ease.powerTwo,
			onComplete: () => { 
				window.scrollTo(0, 0)
				resolve()
			}
		})
	}

	after() {

		const TL = GSAP.timeline()

		TL.to(this.element, {
			delay: 0.3,
			autoAlpha: 0,
			duration: Animations.timing.crossFade,
			ease: Animations.ease.powerTwo,
			
			onComplete: () => {
				window.emitter.emit('pageReady')

				GSAP.set(this.element, {
					x: '100%'
				})
			}
		})
	}
}