import GSAP from 'gsap'

export default class Footer {
	constructor(el, index) {
		this.element 			= el
		this.index 				= index

		this.menuToggle 		= this.element.querySelector('.c-Footer__menu-toggle')
		this.handleMenuClick 	= this.handleMenuClick.bind(this)

		this.modalToggle 		= this.element.querySelector('.c-Footer__modal-toggle')
		this.handleModalClick 	= this.handleModalClick.bind(this)
	}
  
	init() {
		this.menuToggle.addEventListener('click', this.handleMenuClick)
		this.modalToggle.addEventListener('click', this.handleModalClick)
	}

	handleMenuClick(e) {
		window.emitter.emit('menuToggled')
	}

	handleModalClick(e) {
		window.emitter.emit('onTheMenuToggled')
	}

	unmount() {

	}
}
