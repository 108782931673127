import GSAP from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

GSAP.registerPlugin(ScrollTrigger)

export default class Blurb {
	constructor(el) {
		this.element 			= el
		this.paragraph 			= this.element.querySelector('p')
		this.updateBlurb 		= this.updateBlurb.bind(this)

		this.STStart 			= null
		this.STEnd 				= null
		this.stickyScrollers 	= [...document.querySelectorAll('.c-StickyScrollerItem')]
		this.signOff 			= document.querySelector('.c-SignOff')
	}

	init() {

		setTimeout(() => {
			this.resetBlurb()
		}, 300)

		window.emitter.on('updateBlurb', this.updateBlurb)

		if(this.stickyScrollers[0]) {

			this.STStart = ScrollTrigger.create({
				trigger: this.stickyScrollers[0],
				start: 'top bottom',
				onLeaveBack: () => {
					this.resetBlurb()
				},
			})
		}

		// if(this.stickyScrollers.length > 0) {

			this.STEnd = ScrollTrigger.create({
				trigger: this.signOff,
				start: 'top bottom-=100px',
				onEnter: () => { 
					// console.log('yo yo')
					this.resetBlurb()
				}
			})
		// }

		// if(this.stickyScrollers.length > 0) {

		// 	this.STEnd = ScrollTrigger.create({
		// 		trigger: this.stickyScrollers[this.stickyScrollers.length],
		// 		start: 'bottom bottom',
		// 		onEnter: () => { 
		// 			console.log('yo yo')
		// 			this.resetBlurb()
		// 		}
		// 	})
		// }
	}

	updateBlurb(data) {
		this.paragraph.innerHTML = data.blurb
	}

	resetBlurb() {
		this.paragraph.innerHTML = ''
	}

	unmount() {
		window.emitter.off('updateBlurb', this.updateBlurb)

		if(this.STStart) this.STStart.kill()
		if(this.STEnd) this.STEnd.kill()
	}
}
