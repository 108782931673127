import GSAP from 'gsap'
import { Animations } from '../helpers/Animations'

export default class OnTheMenuModal {
	constructor(el, index) {
		this.element 			= el
		this.index 				= index

		this.background 		= this.element.querySelector('.c-OnTheMenuModal__background')
		this.panel 				= this.element.querySelector('.c-OnTheMenuModal__panel')
		this.cursor				= this.element.querySelector('.c-OnTheMenuModal__cursor')
		this.closeButton		= this.element.querySelector('button')

		this.overflowContent 	= this.element.querySelector('.c-OnTheMenuModal__panel__content')

		this.cursorActive 		= false

		this.toggleStateChange 	= this.toggleStateChange.bind(this)
		this.handleMouseMove 	= this.handleMouseMove.bind(this)
	}

	init() {

		window.emitter.on('onTheMenuToggled', this.toggleStateChange)
		this.closeButton.addEventListener('click', this.toggleStateChange)
	}

	toggleStateChange() {

		if(!this.isExpanded) {
			this.show()
			this.addEvents()
			window.emitter.emit('menuClose')
			
		} else {
			this.hide()
			this.removeEvents()
		}

		this.isExpanded = !this.isExpanded
	}

	show() {

		this.element.classList.add('active')

		const tl = GSAP.timeline()

		tl.set(this.element, {
			x: 0
		})

		tl.to(this.background, {
			autoAlpha: 1,
			duration: Animations.timing.opacity
		})

		tl.to(this.panel, {
			x: 0,
			duration: Animations.timing.modal,
			ease: Animations.ease.expoInOut,
		}, '<')
	}

	hide() {

		this.element.classList.remove('active')

		const tl = GSAP.timeline()

		tl.to(this.panel, {
			x: '110%',
			duration: Animations.timing.modal,
			ease: Animations.ease.expoInOut,
		})

		tl.to(this.background, {
			autoAlpha: 0,
			duration: Animations.timing.opacity
		}, '<')

		tl.set(this.element, {
			x: '100%',
			onComplete: () => {
				this.resetScrollPosition()
			}
		})
	}

	resetScrollPosition() {
		this.overflowContent.scrollTop = 0
	}

	handleMouseMove(e) {
		if(!this.cursorActive ) {
			this.element.classList.add('cursor-active')
			this.cursorActive = true
		}

		this.cursor.style.transform = `translate3d(${e.clientX}px, ${e.clientY}px, 0)`
	}

	addEvents() {
		this.background.addEventListener('click', this.toggleStateChange)
		this.background.addEventListener('mousemove', this.handleMouseMove)
	}

	removeEvents() {
		this.element.classList.remove('cursor-active')
		this.cursorActive = false

		this.background.removeEventListener('click', this.toggleStateChange)
		this.background.removeEventListener('mousemove', this.handleMouseMove)
	}

	unmount() {

	}
}
