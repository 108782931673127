const Animations = {

	ease: {
		spring: "elastic.out(1, 0.2)",
		expoInOut: "expo.inOut",
		generic: "expo.out",
		circInOut: "circ.inOut",
		circOut: "circ.out",
		sin: "sin.inOut",
		powerTwo: "power2.inOut",
		powerFour: "power4.inOut"
	},

	timing: {
		crossFade: 0.5,
		generic: 1,
		accordian: 0.6,
		modal: 1,
		opacity: 0.3,
		blur: 2,
	},

	stagger: {
		generic: 0.1,
	}
}

export { Animations }