import GSAP from 'gsap'
import { SplitText } from 'gsap/SplitText'
import { Animations } from '../helpers/Animations'

GSAP.registerPlugin(SplitText)

export default class Preloader {
	constructor(config) {
		this.element 	= config.element

		this.logo		= this.element.querySelector('.c-Preloader__logo')
		this.paragraph 	= this.element.querySelector('p')

		this.tl 		= GSAP.timeline()

		this.init()
	}

	init() {

		this.splitText()
	}

	splitText() {
		this.split = new SplitText(this.paragraph, {
			type: 'words',
			wordsClass: "word",
		})

		GSAP.set(this.paragraph, {
			opacity: 1,
		})

		this.elementsToAnimate = [this.logo, ...this.split.words]

		//this.setIndexes(this.elementsToAnimate)
	}

	animate(resolve) {

		this.tl.to(this.elementsToAnimate, {
			delay: 0.5,
			opacity: 1,
			duration: Animations.timing.blur,
			filter: `blur(0px)`,
			ease:  Animations.ease.powerTwo,
			stagger: 0.1
		})

		this.tl.to(this.element, {
			delay: 1,
			autoAlpha: 0,
			onComplete: () => {
				resolve()
				this.cleanup()
			}
		})
	}

	cleanup() {
		document.body.style.cursor = 'auto'

		window.emitter.emit('pageReady')

		GSAP.set(this.element, {
			yPercent: -110
		})
	}

	setIndexes(elements) {

		elements.forEach((el, idx)=> {
			el.style.setProperty('--index', idx)
		})
	}

	unmount() {}
}
