import GSAP from 'gsap'

export default class MenuModal {
	constructor(el, index) {
		this.element 			= el
		this.index 				= index

		this.isExpanded 		= false
		this.toggleStateChange 	= this.toggleStateChange.bind(this)
		this.setStateFalse 		= this.setStateFalse.bind(this)
		this.button 			= this.element.querySelector('.c-MenuModal__navigation__modal-toggle')

		this.handleButtonClick = this.handleButtonClick.bind(this)
	}

	init() {

		window.emitter.on('menuToggled', this.toggleStateChange)
		window.emitter.on('menuClose', this.setStateFalse)

		this.button.addEventListener('click', this.handleButtonClick)
	}

	handleButtonClick() {
		window.emitter.emit('menuToggled')
		window.emitter.emit('onTheMenuToggled')
	}

	toggleStateChange() {

		if(!this.isExpanded) {
			this.show()
		} else {
			this.hide()
		}

		this.isExpanded = !this.isExpanded
	}

	setStateFalse() {

		if(!this.isExpanded) return
	
		this.isExpanded = false

		this.hide()
	}

	show() {
		GSAP.set(this.element, {
			visibility: 'visible',
			onComplete: () => {
				GSAP.to(this.element, {
					delay: 0.2,
					autoAlpha: 1
				})
			}
		})
	}

	hide() {
		GSAP.to(this.element, {
			delay: 0.2,
			autoAlpha: 0,
			onComplete: () => {
				GSAP.set(this.element, {
					visibility: 'hidden'
				})
			}
		})
	}

	unmount() {

		window.emitter.off('menuToggled', this.toggleStateChange)
		window.emitter.off('menuClose', this.setStateFalse)
	}
}
