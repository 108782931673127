import GSAP from 'gsap'
import { Utils } from '../helpers/Utils'
import { Animations } from '../helpers/Animations'

export default class AccordionItem {
	constructor(el, index) {
		this.element 		= el
		this.index 			= index

		this.button 		= this.element.querySelector('.c-AccordionItem__header')
		this.content		= this.element.querySelector('.c-AccordionItem__content')

		/** LET'S ADD SOME FUNCTIONALITY 
		 * BASED ON THE COMPONENT OPTION 
		 * PARSED FROM THE PHP/HTML
		 */
		this.dataOptions	= this.element.getAttribute('data-options')
		this.parsedData 	= JSON.parse(this.dataOptions)

		this.isExpanded 	= false
		this.isActive 		= false

		if(window.isTouchscreen) {
			this.handleClick = (e) => { 
				this.isExpanded  = !this.isExpanded
				this.stateChangeHandler()
			}
		}
	}

	init() {

		if(window.isTouchscreen) {
			this.addTouchscreenEvents()
		}

		if(this.parsedData.type == 'interactive' && !window.isTouchscreen) {
			/**
			 * HERE WE CAN RUN SOME CODE SPECIFICALLY FOR
			 * THIS STYLE OF ACCORDION
			*/

			this.handleMouseMove 	= this.handleMouseMove.bind(this)
			this.handleMouseLeave 	= this.handleMouseLeave.bind(this)
			this.handleResize 		= Utils.debounceResize(this.resize.bind(this), 300)

			this.offsetTop 			= this.content.offsetTop - 20// cache the value

			this.addInteractiveEvents()
		}
	}

	/**
	 * TOUCHSCREEN
	 */
	stateChangeHandler() {
		if(this.isExpanded === true) {
			this.open()
		} else {
			this.close()
		}
	}

	open() {
		GSAP.to(this.content, {
			height: 'auto',
			duration: Animations.timing.accordian,
			ease: Animations.ease.circInOut,
		})
	}

	close() {
		GSAP.to(this.content, {
			height: 0,
			duration: Animations.timing.accordian,
			ease: Animations.ease.circInOut,
		})
	}

	addTouchscreenEvents() {
		this.button.addEventListener('click', this.handleClick)
	}

	removeTouchscreenEvents() {
		this.button.removeEventListener('click', this.handleClick)
	}

	/**
	 * IF THE COMPONENT TYPE == 'INTERACTIVE'
	 * THEN WE RUN THE MOUSEMOVE LOGIC
	 */
	addInteractiveEvents() {
		this.button.addEventListener('mousemove', this.handleMouseMove)
		this.button.addEventListener('mouseleave', this.handleMouseLeave)
		window.addEventListener('resize', this.handleResize)
	}

	removeInteractiveEvents() {
		this.button.removeEventListener('mousemove', this.handleMouseMove)
		this.button.removeEventListener('mouseleave', this.handleMouseLeave)
		window.removeEventListener('resize', this.handleResize)
	}

	handleMouseMove(e) {

		if(!this.isActive) {
			this.element.classList.add('active')
			this.isActive = true
		}

		this.content.style.transform = `translate3d(${e.clientX}px, ${e.clientY - this.offsetTop}px, 0)`
	}

	handleMouseLeave(e) {
		this.isActive = false
		this.element.classList.remove('active')
	}

	resize() {
		this.offsetTop = this.content.offsetTop - 20
	}

	unmount() {

		if(window.isTouchscreen) {
			this.isExpanded = false

			this.removeTouchscreenEvents()
		}

		if(this.parsedData.type == 'interactive') {
		
			this.isActive = false
			this.removeInteractiveEvents()
		}
	}
}
